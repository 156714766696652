<template>
    <div id="radar-container" class="radar-container"></div>
</template>

<script>
    import Raphael from "raphael"

    export default {
        name: "RadarChart",
        components: {
        },

        props: {
            chartData: {
                type: Object,
                required: true,
            },
        },

        data: function () {
            return {
                chart: null,
            };
        },

        mounted() {
            let vm = this;

            Raphael.prototype.radarChart = function (info) {

                // Reversed order of options for both axis
                info.subfields.reverse()
                info.levels.reverse()

                // Variables
                var paper = this;
                var chart = this.set();

                // Space gap in the bottom to put level labels
                var bottomGap = 20;

                // activate hover effect
                var overEffect = true;

                // Defines the type of effect if overEffect is active : resize,highlight
                var effectType = '';

                // Width of the chart
                var divW = this.width;

                // Height of the chart : Must be half of divW
                var divH = this.height;

                // Origin Coordinate : X axis
                var originX = divW / 2;

                // Origin Coordinate : Y axis
                var originY = divH - bottomGap;

                // Inner Radius
                var radius = divW / 12;

                // Outer Radius => Observed
                var fullRadius = divW / 2 - bottomGap;

                // Offset to change origin to left center of the circuference
                var originAngle = Math.PI / 2;

                // Width of each level arc
                var tempOffSet = (info.levels.length - info.levels.length) * bottomGap;
                var layerW = [((divW / 2) - radius) / info.levels.length] - tempOffSet;

                // Grades steps for each subfields
                var stepGrades = 180 / info.subfields.length;

                // Stores the current element being hover in the radar
                var currentHover = null;

                // Store separation lines
                var sepLines = [];

                // to Store each chart region reference for the hover effect
                var chartData = [];

                // An array to store radar images
                var logosData = [];

                // An object to store logos count per segment
                var companiesCount = {};

                // var currenSegmentImages = [];

                // Color Settings
                var colors = {'red': '#f13d3d', 'grey': '#a5a5a5', 'dark': '#3b3b3b'};

                // Text Settings
                var text = {
                    'font_family': 'Arial, Helvetica Neue, Helvetica, sans-serif',
                    'font_size': 12,
                    'font_size_y': 14
                };

                // var levelTypeAdded = false;

                // limit of companies to show per quadrant
                var compLimit = 20;

                // Methods
                var pointByAngleRadius = function (angle, radius) {
                    var X = originX + Math.sin(angle) * radius;
                    var Y = originY + Math.cos(angle) * radius;

                    return {'x': X, 'y': Y};
                }
                var getArcCentroid = function (origin, arcStartAngle, arcEndAngle, initRadio, endRadio) {
                    let middleAngle = (arcEndAngle - arcStartAngle) / 2 + arcStartAngle;
                    var X = origin.x + Math.sin(middleAngle) * [(endRadio - initRadio) / 2 + initRadio];
                    var Y = origin.y + Math.cos(middleAngle) * [(endRadio - initRadio) / 2 + initRadio];

                    return {'x': X, 'y': Y};
                }
                var loadCompaniesCount = function (data) {
                    for (var j = 0; j < data.length; j++) {

                        if (typeof companiesCount[data[j].level] == 'undefined') {
                            companiesCount[data[j].level] = {};
                        }
                        if (typeof companiesCount[data[j].level][data[j].subfield] == 'undefined') {
                            companiesCount[data[j].level][data[j].subfield] = 0;
                        }

                        companiesCount[data[j].level][data[j].subfield]++;
                    }
                }
                var countCompaniesPerSegment = function (l, i) {
                    var count = 0;
                    var subfield = info.subfields[i].id
                    var level = info.levels[l].id;

                    if (typeof companiesCount[level] != 'undefined') {
                        if (typeof companiesCount[level][subfield] != 'undefined') {
                            count = companiesCount[level][subfield];
                        }
                    }

                    if (count > compLimit) {
                        return compLimit;
                    }

                    return count;
                }
                var getPositionObject = function (obj) {
                    return {
                        'level': obj.data('level-pos'),
                        'subfield': obj.data('subfield-pos')
                    };
                }
                var createSeparationLines = function (angle) {

                    var currentAngle = Raphael.rad(angle);
                    var startA = originAngle + currentAngle;

                    var startX = originX + Math.sin(startA) * (radius)
                    var startY = originY + Math.cos(startA) * (radius)
                    var endX = originX + Math.sin(startA) * (fullRadius);
                    var endY = originY + Math.cos(startA) * (fullRadius);
                    let line = "M "
                    line += String(startX) + " " + String(startY) + " L "
                    line += String(endX) + " " + String(endY) + " z"

                    var sepL = paper.path(line).attr({"stroke-width": 2, "stroke": "#fff"}).toFront();
                    sepLines.push(sepL);

                    return sepL;
                };
                var addLevelLabels = function (l) {
                    var name = info.levels[l].name
                    var levelLabelsAttr = {
                        fill: colors.dark,
                        'font-size': text.font_size - 2,
                        stroke: 'none',
                        'font-family': text.font_family,
                        'title' : name
                    };

                    var maxLength = 118 / (info.levels.length * 2 + 1);

                    if (name.length > maxLength) {
                        console.log(name + ' - ' + name.length + ' - ' + maxLength)
                        name = name.substring(0, maxLength - 2) + '...';
                    }

                    paper.text(originX + radius + layerW - (layerW / 2), originY + 13, name).attr(levelLabelsAttr);
                    paper.text(originX - radius - layerW + (layerW / 2), originY + 13, name).attr(levelLabelsAttr);

                    levelLabelsAttr.fill = colors.dark;

                    if (typeof info.levels_title != 'undefined') {
                        chart.push(paper.text(originX, originY + 10, info.levels_title).attr(levelLabelsAttr).toFront());
                    }

                    /* ToDo: remove
                    if (!levelTypeAdded) {

                        var levelTypeTitle = 'Partnership Level';
                        if (info.levels[l].name == 'Late' || info.levels[l].name == 'Mid' || info.levels[l].name == 'Early') {
                            levelTypeTitle = 'Maturity';
                        }

                        if (typeof info.levels_title != 'undefined') {
                            levelTypeTitle = info.levels_title;
                        }

                        chart.push(paper.text(originX, originY + 10, levelTypeTitle).attr(levelLabelsAttr).toFront());
                        levelTypeAdded = true;
                    }

                    */
                }
                var addSubfieldLabels = function (labelA, index) {
                    var labelSet = paper.set();

                    // var caption = $.trim(info.subfields[index].name);
                    var caption = info.subfields[index].name
                    var parts = caption.split(' ');
                    var textSettings = {
                        'text-anchor': 'middle',
                        'font-size': text.font_size_y,
                        fill: colors.dark,
                        'font-family': text.font_family,
                        'cursor': 'default'
                    };

                    var rOffset = 50;
                    for (var i = parts.length - 1; i >= 0; i--) {
                        let partialText

                        if (parts[i].length <= 4 && typeof parts[(i - 1)] != 'undefined') {
                            partialText = parts[i - 1] + ' ' + parts[i];
                            i--;
                        } else {
                            partialText = parts[i];
                        }

                        var label = paper.text(
                            originX + Math.sin(labelA) * (fullRadius - bottomGap + rOffset),
                            originY + Math.cos(labelA) * (fullRadius - bottomGap + rOffset), partialText)
                            .attr(textSettings);

                        labelSet.push(label);
                        rOffset = rOffset + 14;
                    }
                    labelSet.toFront();

                    if (labelA > Math.PI / 2 && labelA < 1.5 * Math.PI) {
                        labelSet.rotate(180 - labelA * 180 / Math.PI)
                    } else {
                        labelSet.rotate(360 - labelA * 180 / Math.PI)
                    }
                    return labelSet;
                }
                var mouseOverCallback = function () {

                    var pos = getPositionObject(this);

                    currentHover = chartData[pos.level][pos.subfield]['hover'];
                    currentHover.attr({'stroke-opacity': 2}).toFront();
                }
                var mouseOutCallback = function () {
                    currentHover.attr({'stroke-opacity': 0});
                }
                var searchCompaniesInSegment = function (l, i) {
                    var level = info.levels[l].id;
                    var subfield = info.subfields[i].id;
                    var companies = [];

                    for (var j = 0; j < info.data.length; j++) {
                        if (info.data[j].subfield == subfield && info.data[j].level == level) {

                            logosData[l][i][info.data[j].name] = null;
                            if (companies.length < compLimit) {
                                companies.push(info.data[j]);
                            }
                        }
                    }

                    return companies;
                }
                var getResizeLimitsByCount = function (level, count) {
                    /** Limits based on image proportions.
                     * @limits;
                     *  ___
                     * |   |                     __________
                     * |   | width     = 50;    |  w > h   |  height   = 20;
                     * |   | maxHeight = 34;    |__________|  maxWidth = 92;
                     * |   |
                     * -----
                     */
                    var limits = {
                        'vertical': {'width': 50, 'maxHeight': 34},
                        'horizontal': {'height': 20, 'maxWidth': 92},
                        'square': 36,
                        'size': 's1.8'
                    };

                    if (count >= 1 && count < 3) {
                        //limits = { 'vertical': {'width':62, 'maxHeight':48}, 'horizontal': {'height': 32, 'maxWidth':88}, 'square': 52, 'size' : 's1.5'};
                        limits = {
                            'vertical': {'width': 62, 'maxHeight': 48},
                            'horizontal': {'height': 32, 'maxWidth': 88},
                            'square': 52,
                            'size': 's1.5'
                        };
                    } else if (count >= 3 && count < 5) {
                        //limits = { 'vertical': {'width':52, 'maxHeight':40}, 'horizontal': {'height': 26, 'maxWidth':62}, 'square': 42, 'size' : 's2.2'};
                        limits = {
                            'vertical': {'width': 36, 'maxHeight': 34},
                            'horizontal': {'height': 20, 'maxWidth': 54},
                            'square': 36,
                            'size': 's2.4'
                        };
                    } else if (count >= 5 && count < 7) {
                        limits = {
                            'vertical': {'width': 32, 'maxHeight': 30},
                            'horizontal': {'height': 20, 'maxWidth': 50},
                            'square': 32,
                            'size': 's2.4'
                        };
                    } else if (count >= 7 && count < 8) {
                        limits = {
                            'vertical': {'width': 24, 'maxHeight': 20},
                            'horizontal': {'height': 16, 'maxWidth': 50},
                            'square': 28,
                            'size': 's2.6'
                        };
                    } else if (count >= 9 && count < 10) {
                        limits = {
                            'vertical': {'width': 22, 'maxHeight': 17},
                            'horizontal': {'height': 13, 'maxWidth': 48},
                            'square': 24,
                            'size': 's3'
                        };
                    } else {
                        limits = {
                            'vertical': {'width': 20, 'maxHeight': 14},
                            'horizontal': {'height': 12, 'maxWidth': 44},
                            'square': 20,
                            'size': 's3'
                        };
                    }

                    for (var i = 0; i < (info.levels.length - level - 1); i++) {
                        limits.vertical.width = limits.vertical.width * 0.75;
                        limits.vertical.maxHeight = limits.vertical.maxHeight * 0.75;
                        limits.horizontal.width = limits.horizontal.width * 0.75;
                        limits.horizontal.maxHeight = limits.horizontal.maxHeight * 0.75;
                        limits.square = limits.square * 0.75;
                    }

                    return limits;
                }
                var resizeBySide = function (image, side, value) {
                    var ratio = 1;

                    if (side == 'height') {
                        ratio = value / image.height;
                        return image.width * ratio;
                    } else {
                        ratio = value / image.width;
                        return image.height * ratio;
                    }
                }
                var resizeImage = function (image, limits) {

                    // console.log(image.distribution(4));

                    var width = image.width;
                    var height = image.height;
                    // var diff = image.width - image.height;

                    if (image.width > image.height) {
                        // resize to fixed height
                        height = limits.horizontal.height;
                        width = resizeBySide(image, 'height', height);
                        if (width > limits.horizontal.maxWidth) {
                            width = limits.horizontal.maxWidth;
                            height = resizeBySide(image, 'width', width);
                        }
                    } else if (image.height > image.width) {
                        // resize to fixed width
                        width = limits.vertical.width;
                        height = resizeBySide(image, 'width', width);
                        if (height > limits.vertical.maxHeight) {
                            height = limits.vertical.maxHeight;
                            width = resizeBySide(image, 'height', height);
                        }
                    } else {
                        width = limits.square;
                        height = limits.square;
                    }

                    return {'width': width, 'height': height};
                }

                var getCompaniesPositions = function (l, i, startA, endA, radius) {

                    var points = [];
                    var count = countCompaniesPerSegment(l, i);
                    var subFieldAngle = endA - startA;
                    var middleAngle = startA + (subFieldAngle) / 2;

                    switch (count) {
                        case 1:
                            points.push(getArcCentroid({'x': originX, 'y': originY}, startA, endA, radius, (radius + layerW)))
                            break;
                        case 2:
                            var firstAngle
                            var secondAngle
                            var referenceRadio

                            // if angle between 30 AND 150 => divide by middle radius layerW/2
                            // if angle out of the previus => divide by middle angle subFieldAngle/2
                            if (originAngle + Raphael.rad(30) <= middleAngle && middleAngle <= originAngle + Raphael.rad(150)) {

                                firstAngle = startA + subFieldAngle / 3;
                                secondAngle = startA + (subFieldAngle / 3 * 2);
                                referenceRadio = radius + layerW / 2;

                                points.push(pointByAngleRadius(firstAngle, referenceRadio));
                                points.push(pointByAngleRadius(secondAngle, referenceRadio));
                            } else {
                                firstAngle = startA + subFieldAngle / 4;
                                secondAngle = startA + (subFieldAngle / 4 * 3);
                                referenceRadio = radius + layerW / 2;

                                points.push(pointByAngleRadius(firstAngle, referenceRadio));
                                points.push(pointByAngleRadius(secondAngle, referenceRadio));
                            }
                            break;
                        case 4:
                        case 6:
                        case 8:
                        case 10:
                        case 12:
                        case 14:
                        case 16:
                        case 18:
                        case 20:
                        case 22:
                        case 24:
                        case 26:
                            var angleSegment

                            if (originAngle + Raphael.rad(88) < middleAngle && middleAngle < originAngle + Raphael.rad(92)) {
                                var radiusOffset = layerW / 6;
                                angleSegment = subFieldAngle / count;
                                var midRadius = radius + layerW / 2;
                                var flag = 'positive'; // positive
                                for (let j = 1; j <= count; j++) {
                                    let currentAngle = startA + (angleSegment * j) - (angleSegment / 2);
                                    if (flag == 'positive') { // positive
                                        radiusOffset = radiusOffset * (-1);
                                        flag = 'negative';  // negative
                                    } else {
                                        radiusOffset = radiusOffset * (-1);
                                        flag = 'positive';  // positive
                                    }
                                    points.push(pointByAngleRadius(currentAngle, midRadius - radiusOffset));
                                }
                            } else {
                                angleSegment = subFieldAngle / (count / 2 + 1);
                                for (let j = 1; j <= count / 2; j++) {

                                    let currentAngle = startA + (angleSegment * j);
                                    let currentAngleDeltaMinus = currentAngle - (angleSegment / 2);
                                    let currentAngleDeltaPlus = currentAngle + (angleSegment / 2);

                                    if (currentAngle >= middleAngle) {
                                        points.push(pointByAngleRadius(currentAngleDeltaPlus, radius + layerW / 3));
                                        points.push(pointByAngleRadius(currentAngle, radius + (layerW / 5 * 3)));
                                    } else {
                                        points.push(pointByAngleRadius(currentAngle, radius + layerW / 3));
                                        points.push(pointByAngleRadius(currentAngleDeltaMinus, radius + (layerW / 5 * 3)));
                                    }
                                }
                            }
                            break;
                        case 3:
                        case 5:
                        case 7:
                        case 9:
                        case 11:
                        case 13:
                        case 15:
                        case 17:
                        case 19:
                        case 21:
                        case 23:
                        case 25:
                            // Dividir por angulo intercalado * working 100%
                            radiusOffset = layerW / 6;
                            angleSegment = subFieldAngle / (count + 1);
                            midRadius = radius + layerW / 2;
                            flag = 'positive'; // positive
                            for (var j = 1; j <= count; j++) {
                                let currentAngle = startA + (angleSegment * j);
                                if (flag == 'positive') { // positive
                                    radiusOffset = radiusOffset * (-1);
                                    flag = 'negative';  // negative
                                } else {
                                    radiusOffset = radiusOffset * (-1);
                                    flag = 'positive';  // positive
                                }
                                points.push(pointByAngleRadius(currentAngle, midRadius - radiusOffset));
                            }
                            break;
                    }

                    return points;
                }

                var addCompanyLogo = function (l, i, startA, endA, radius) {
                    // var level       = info.levels[l].id;
                    // var subfield    = info.subfields[i].id;
                    var companies = searchCompaniesInSegment(l, i);
                    var sizeLimits = getResizeLimitsByCount(l, countCompaniesPerSegment(l, i));
                    // var $captionDiv = $('.no-logo-container');
                    var $captionDiv = document.getElementsByClassName('no-logo-container')

                    // var $radarContainer = $('#radar-container');
                    var $radarContainer = document.getElementById('radar-container')
                    var companiesPositions = getCompaniesPositions(l, i, startA, endA, radius);

                    // console.log(companies);

                    if (companies.length > 0) {

                        // var noLogoIndex = 0;
                        for (var k = 0; k < companies.length; k++) {
                            var tmpImage = new Image();
                            var noLogo

                            // Company Position
                            if (companies[k].logo.length > 0) {
                                if (typeof companies[k].logo_code != 'undefined') {
                                    tmpImage.src = 'data:image/png;base64,' + companies[k].logo_code.base64;
                                } else {
                                    tmpImage.src = companies[k].logo;
                                }
                                noLogo = 'yes';
                            } else if (typeof companies[k].logo_code != 'undefined') {
                                tmpImage.src = 'data:image/png;base64,' + companies[k].logo_code.base64;
                                noLogo = companies[k].logo_code.no_logo;
                            } else {
                                noLogo = 'no';
                                tmpImage.src = '/images/no_logo_small.png';
                            }
                            // $(tmpImage).data('k',k);
                            tmpImage.dataset.k = k
                            // $(tmpImage).data('no-logo', noLogo);
                            tmpImage.dataset.noLogo = noLogo
                            tmpImage.onload = function () {

                                // var currentIndex = $(this).data('k');
                                var currentIndex = this.dataset.k
                                // var noLogo = $(this).data('no-logo');
                                var noLogo = this.dataset.noLogo
                                var compos = companiesPositions[currentIndex];

                                var size = resizeImage(this, sizeLimits);

                                var xOffset = compos.x - (size.width / 2);
                                var yOffset = compos.y - (size.height / 2);

                                let img = paper.image(this.src, xOffset, yOffset, size.width, size.height).attr({cursor: 'pointer'}).toFront();
                                img.data('k', currentIndex).data('logo', noLogo).data('l', l).data('i', i);
                                // $(img.node).removeAttr('preserveAspectRatio');
                                img.node.removeAttribute("preserveAspectRatio")

                                img.click(function () {
                                    // const startupUrl = '/startups/' + companies[currentIndex].company_id
                                    // window.open(startupUrl, '_blank')

                                    vm.$emit('startupLogoClick', companies[currentIndex].company_id)
                                });

                                img.mouseover(function () {
                                    this.stop().animate({transform: sizeLimits.size}, 150, "linear").toFront()
                                }).mouseout(function () {
                                    this.stop().animate({transform: ""}, 800, "elastic").toFront()
                                })

                                img.mousemove(function (e) {
                                    var k = this.data('k');
                                    // var l = this.data('l');
                                    // var i = this.data('i');
                                    var logo = this.data('logo');

                                    if (logo == 'yes') {
                                        return;
                                    }

                                    // e = jQuery.event.fix(e);
                                    let mouseX = e.pageX;
                                    let mouseY = e.pageY;

                                    // $captionDiv.html(companies[k].name);
                                    $captionDiv.innerHTML = companies[k].name
                                    // $captionDiv.css('display', 'block');
                                    $captionDiv.style.display = 'block'
                                    // $captionDiv.css('top', mouseY + 6 - $radarContainer.offset().top);
                                    $captionDiv.style.top = mouseY + 6 - $radarContainer.offset().top

                                    var xVal = mouseX - $radarContainer.offset().left;
                                    // $captionDiv.css('left', xVal + 8);
                                    $captionDiv.style.left = xVal + 8
                                });

                                // point reference
                                // paper.rect(compos.x, compos.y, 2, 2);
                            }
                        }
                    }

                    return true;
                }
                var addDTLogo = function (code) {
                    var src = 'data:image/png;base64,' + code;
                    paper.image(src, originX - 40, originY - 48, 80, 39).attr({}).toFront();
                }

                // Beging Chart Creation
                // chart.push(paper.circle(originX, originY, radius - 4).attr({ 'stroke': '#ccc', 'stroke-width':4}));
                // chart.push(paper.circle(originX, originY, fullRadius).attr({ 'stroke': '#fff', 'stroke-width':0 }));

                loadCompaniesCount(info.data);

                var resultCount = 0;

                //loop through each level : ['observed','engaged','strategic']
                for (var l = 0; l < info.levels.length; l++) {

                    chartData[l] = [];
                    logosData[l] = [];

                    var startA = originAngle;
                    var endA = 0;

                    addLevelLabels(l);

                    if (typeof info.dt_logo != 'undefined') {
                        addDTLogo(info.dt_logo.base64);
                    }

                    for (var i = 0; i < info.subfields.length; i++) {

                        logosData[l][i] = [];

                        if (i > 0) {
                            startA = endA;
                        }
                        endA = startA + Raphael.rad(stepGrades);

                        var blX = originX + Math.sin(endA) * radius;
                        var blY = originY + Math.cos(endA) * radius;
                        var brX = originX + Math.sin(startA) * radius;
                        var brY = originY + Math.cos(startA) * radius;
                        var tplX = originX + Math.sin(endA) * (radius + layerW);
                        var tplY = originY + Math.cos(endA) * (radius + layerW);
                        var tprX = originX + Math.sin(startA) * (radius + layerW);
                        var tprY = originY + Math.cos(startA) * (radius + layerW);

                        // var strokeW = (fullRadius/ info.levels.length )/2
                        // var startRadius = divW/4 - .5*strokeW - 40;
                        // var strokeRadius = startRadius  +strokeW +  (strokeW +2)* l

                        let path = [
                            ["M", blX, blY],
                            ["A", radius, radius, 0, 0, 1, brX, brY],
                            ["L", tprX, tprY],
                            ["A", radius + layerW, radius + layerW, 0, 0, 0, tplX, tplY],
                            ["L", blX, blY],
                            ['z']
                        ];

                        if (l == 0) {

                            // Add separtions lines
                            let line = createSeparationLines(i * stepGrades);
                            chart.push(line);

                            // Add subfields labels
                            var labelSubfield = (startA + endA) / 2;
                            let label = addSubfieldLabels(labelSubfield, i);
                            chart.push(label);
                        }

                        //init the chartobject
                        chartData[l].push({
                            'hover': {},
                            'layersSet': this.set(),
                            'layersObj': {},
                            'label': {},
                            'region': '',
                            'regionLabel': {},
                            'regionDivider': false,
                            'subfieldPos': i,
                        });

                        // Create hover effect delimiter lines
                        var hblX = originX + Math.sin(startA) * radius;
                        var hblY = originY + Math.cos(startA) * radius;
                        var hbrX = originX + Math.sin(endA) * radius;
                        var hbrY = originY + Math.cos(endA) * radius;
                        var htplX = originX + Math.sin(startA) * (radius + layerW);
                        var htplY = originY + Math.cos(startA) * (radius + layerW);
                        var htprX = originX + Math.sin(endA) * (radius + layerW);
                        var htprY = originY + Math.cos(endA) * (radius + layerW);

                        let outline = [
                            ["M", hblX, hblY],
                            ["A", radius, radius, 0, 0, 0, hbrX, hbrY],
                            ["L", htprX, htprY],
                            ["A", radius + layerW, radius + layerW, 0, 0, 1, htplX, htplY],
                            ["L", hblX, hblY],
                            ['z']
                        ];

                        // Look for logos in current segment
                        // console.log(l +' '+ i);
                        addCompanyLogo(l, i, startA, endA, radius);
                        // countCompaniesPerSegment(l, i);

                        // On hover highlight lines
                        if (overEffect && effectType == 'highlight') {
                            var pathSettings = {
                                "stroke-width": 2,
                                "stroke": "#f13d3d",
                                'stroke-opacity': 0,
                                'fill-opacity': 0,
                                'fill': '#f13d3d'
                            };
                            chartData[l][i]['hover'] = paper.path(outline).attr(pathSettings).data('level-pos', l).data('subfield-pos', i);
                            chartData[l][i]['hover'].mouseover(mouseOverCallback).mouseout(mouseOutCallback);
                            chart.push(chartData[l][i]['hover']);
                        }

                        var blockSettings = {fill: info.levels[l].fill, 'stroke-width': 0, 'stroke': info.levels[l].fill};
                        var block = paper.path(path).attr(blockSettings).toBack().data('level-pos', l).data('subfield-pos', i);

                        if (overEffect && effectType == 'resize') {
                            block.mouseover(function () {
                                this.stop().animate({transform: "s1.2"}, 150, "linear").attr({
                                    'stroke-width': 2,
                                    stroke: '#f13d3d'
                                }).toFront();
                            }).mouseout(function () {
                                var pos = getPositionObject(this);
                                var settings = {
                                    fill: info.levels[pos.level].fill,
                                    'stroke-width': 0,
                                    'stroke': info.levels[pos.level].fill
                                };
                                this.stop().animate({transform: ""}, 800, "elastic").attr(settings).toBack();
                            });
                        }


                        resultCount = resultCount + countCompaniesPerSegment(l, i);

                        chart.push(block);
                    }
                    radius += layerW;
                }

                return paper;
            }


            Raphael.prototype.destroy = function () {
                this.clear()
                this.remove()
            }
        },

        watch: {
            chartData(newValue) {
                if (!this.chart) {
                    this.chart = Raphael('radar-container', 680, 420).radarChart(newValue)

                    this.chart.setViewBox(-70, 0, 820, 420, true)
                    this.chart.setSize('100%', '100%')
                } else {
                    this.chart.destroy()
                    this.chart = Raphael('radar-container', 680, 420).radarChart(newValue)

                    this.chart.setViewBox(-70, 0, 820, 420, true)
                    this.chart.setSize('100%', '100%')
                }
            },
        },

        methods: {

        }
    }
</script>
