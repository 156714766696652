<template>
    <b-popover custom-class="popover-radar"
        ref="startupDetailsPopover"
        placement="bottom"
        :disabled="true"
        :target="target"
        :show.sync="popoverShown"
    >
        <div>
            <b-button @click="hidePopover" class="close" aria-label="Close">
                <span class="d-inline-block" aria-hidden="true">&times;</span>
            </b-button>
            
            <div class="popover-radar__content">
                <div class="image-block-wrapper">
                    <div class="image-block image-block--mds">
                        <img :src="LogoHelper.getLogo(startupDetails.logo)" :alt="startupDetails.name"/>
                    </div>
                    
                    <div>
                        <h5 class="heading5"><a :href="'/startups/' + startupId" target="_blank">{{ startupDetails.name }}</a></h5>

                        <a :href="startupDetails.website" target="_blank">
                            {{ startupDetails.website }}
                        </a>
                    </div>
                </div>
                
                <p>{{ startupDetails.description }}</p>

                <p v-if="startupDetails.trackers.length">
                    Trackers:
                    <span v-for="(tracker, index) in startupDetails.trackers" :key="tracker.id">
                        <template v-if="index !== 0">, </template>
                        <a :href="'/trackers/' + tracker.id" target="_blank">{{ tracker.name }}</a>
                    </span>
                </p>
            </div>
            <footer class="popover-radar__footer">
                <ul class="overview-list">
                    <li v-if="startupDetails.raised">
                        <span class="overview-list__icon icon-funding icon-funding--light"></span>
                        {{ stringHelper.formatNumberShort(startupDetails.raised) }} raised
                    </li>
                    <li v-if="startupDetails.stage">
                        <span class="overview-list__icon icon-startups icon-startups--light"></span>
                        {{ startupDetails.stage }}
                    </li>
                    <li v-if="startupDetails.employees">
                        <span class="overview-list__icon icon-employees--light"></span>
                        {{ startupDetails.employees }} employees
                    </li>
                </ul>
            </footer>
        </div>
    </b-popover>
</template>

<script>
    import startups from "../../services/startups.service";

    export default {
        name: 'StartupDetailsPopover',
        props: {
            target: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                popoverShown: false,
                startupId: null,
                startupDetails: {
                    name: '',
                    description: '',
                    employees: '',
                    logo: '',
                    raised: '',
                    stage: '',
                    tags: [],
                    trackers: [],
                    website: '',
                },
            }
        },
        methods: {
            hidePopover() {
                this.popoverShown = false
            },
            showPopover() {
                this.popoverShown = true
            },
            openPopover(startupId) {
                this.startupId = startupId

                this.getStartupDetails()
            },
            getStartupDetails() {
                startups.getDetails(this.startupId, true).then(response => {
                    this.startupDetails = response.data

                    this.showPopover()
                })
            },
        }
    }
</script>
